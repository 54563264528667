import classes from './list.module.scss'
import { Card } from '../Card'
import { ICategoryData } from '../../model/News'
import { useQuery } from '@tanstack/react-query'
import { getFetcher } from '../../../../shared/api/fetcher/getFetcher'
import { useEffect } from 'react'

interface Props {
	selectedCategory: string | null
}
export const List = ({ selectedCategory }: Props) => {
	const { data, isError, isLoading, refetch } = useQuery<ICategoryData>({
		queryKey: ['news', selectedCategory],
		queryFn: () => {
			return getFetcher(`/category/get-by-id/${selectedCategory}`)
		},
		enabled: false
	})

	useEffect(() => {
		if (selectedCategory) { refetch() }
	}, [selectedCategory])


	if (isError || isLoading) {
		return null
	}

	return <div className={classes.items}>
		{data?.data[0].news.map((item) =>
			<Card 
				key={item.id} 
				newsItem={item} 
				hideImage={selectedCategory !== null && (Number(selectedCategory) === 5 || Number(selectedCategory) === 4)}
			/>
		)}
	</div>
}