import { useEffect, useState } from 'react'
import Button from '../../../ui/Button'
import classes from './header.module.scss'
import ModalDownload from '../../../ui/мodalDownload/ModalDownload'
import Title from '../../../ui/Title'
import useFetch from '../../../../shared/api/useFetch'
import { ICategory } from '../../model/News'
import classNames from 'classnames'
import { getFetcher } from '../../../../shared/api/fetcher/getFetcher'
import { useQuery } from '@tanstack/react-query'

interface Props {
	setSelectedCategory: (value: string) => void
	selectedCategory: string | null
}
export const Header = ({ setSelectedCategory, selectedCategory }: Props) => {
	const [isModal, setModal] = useState(false)
	const onClose = () => setModal(false)
	const lang = localStorage.getItem("lang");
	const { data, isError, isLoading, refetch } = useQuery<{ data: ICategory[] }>({
		queryKey: ['category', selectedCategory],
		queryFn: () => {
			return getFetcher(`category/get`)
		}
	})

	useEffect(() => {
		if (data?.data) {
			if (!selectedCategory) {
				setSelectedCategory(String(data.data[1].id))
			}
		}
	}, [data])

	const HandleSubmit = (id: string) => {
		if (selectedCategory !== id) {
			setSelectedCategory(id)
		}
	}

	if (isError || isLoading || !data) {
		return null
	}


	return <div className={classes.header}>
		<ModalDownload
			visible={isModal}
			onClose={onClose} />
		<Title
			className={classes.title}
			title={lang === "RU" ? "Новости " : "Climate "}
			subtitle={lang === "RU" ? "климата" : "News"}
		/>
		<div className={classes.buttons}>
			{data?.data.map((item) =>
				<Button
					type='outline'
					middle
					key={item.id}
					className={classNames(
						classes.btn,
						item.id == Number(selectedCategory) && classes.btn_2,
						classes.btn_1)}
					onClick={() => HandleSubmit(String(item.id))}
				>
					{item.name}
				</Button>
			)}
		</div>
	</div>
}