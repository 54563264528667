import { Outlet, useLocation } from "react-router-dom"
import Header from "../core/Header"
import Footer from "../core/Footer"
import classes from './layout.module.scss'
import classNames from "classnames"
const Layout = () => {

	return (
		<>
			<Header />

			<div className={classNames(classes.root)}>
				<div className={classes.wrap}>
					<Outlet />
				</div>
			</div>


			<Footer />
		</>
	)
}


export default Layout