import financingIcon from "./assets/icon/financing.svg";
import locale from "../assets/icon/locale.svg";
import staff from "../assets/icon/staff.svg";
import turnover from "../assets/icon/turnover.svg";

export  const cardCriteriaDataRU = [
  {
    id: 1,
    title: "Локально зарегистрированный бизнес.",
    icon: locale,
    description:
      "Локально зарегистрированное предприятие в одной из трех стран-участниц проекта и отвечающее требованиям комплексной проверки (due diligence) ",
  },
  {
    id: 2,
    title: "Персонал",
    description: `Имеет в штате как минимум 5 сотрудников и обеспечивает надлежащие механизмы защиты работников`,
    icon: staff,
  },
  {
    id: 3,
    title: "Оборот",
    icon: turnover,
    description: `Имеет годовой оборот не менее 420,000 сомони, который отражен на аудиторских отчётах`,
  },
  {
    id: 4,
    title: "Со-финансирование",
    description:
      "Вносит не менее 40% со-финансирования (наличными и/или в натуральной форме)",
    icon: locale,
  },
  {
    id: 5,
    title: "Инновация",
    icon: locale,
    description:
      "Инновационные решения, которые способствуют устойчивости к изменению климатаИнновационные решения, которые способствуют устойчивости к изменению климата",
  },
  ];