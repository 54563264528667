import React from 'react'
import styles from "./CardLogo.module.scss"
import { AnimationOnScroll } from 'react-animation-on-scroll'

interface ICardLogo {
    src: string
    alt?: string
}

export default function CardLogo({src,alt}: ICardLogo) {
  return (
    <AnimationOnScroll animateIn="animate__bounceIn"  >
    <div className={styles.Card}>
        <img className={styles.logo} src={src} alt={alt} /></div>
        </AnimationOnScroll>
  )
}
