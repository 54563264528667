import { useParams } from 'react-router-dom'
import classes from './profiles.module.scss'
import { useEffect, useState } from 'react';
import { Breakpoints } from '../../../../shared/ui/Breakpoints';
import { getFetcher } from '../../../../shared/api/fetcher/getFetcher';
import { useQuery } from '@tanstack/react-query'
import { IProject, IProjectData } from '../../model/project';
import { Card } from '../Card/Card';
import Title from '../../../ui/Title';
import { Pagination } from '../../../../shared/ui/Pagination';

export const Profiles = () => {
    const { id } = useParams()
    const lang = localStorage.getItem("lang");
	const [pageIndex, setPageIndex] = useState(0)
    const { data, isError, isLoading } = useQuery<{ data: IProject }>({
		queryKey: ['project', id],
		queryFn: () => {
			return getFetcher(`/project/get-by-id/${id}`)
		}
	})

	const handlePageClick = (event: { selected: number }) => {
		setPageIndex(event.selected)
	}

    const { data: projects } = useQuery<IProjectData>({
		queryKey: ['projects', pageIndex],
		queryFn: () => {
			return getFetcher(`/project/get?page=${pageIndex + 1}`)
		}
	})

	useEffect(() => {
		if (lang) {

		} else {
			localStorage.setItem("lang", 'RU')
		}
	}, [0])

    const breakpointsData = {
		data: [
			{
				lang_id: 'RU',
				items: [
					{
						name: 'Главная',
						link: '/',
					}, {
						name: 'Проект',
						link: '/news',
					}
				]
			},
			{
				lang_id: "GB",
				items: [
					{
						name: 'Main',
						link: '/',
					}, {
						name: 'Profiles',
						link: '/projects',
					}
				]
			}
		]
	}
    if(isError && isLoading) {
        return null
    }

    let BreakpointsFilter = breakpointsData.data.filter((el) => el.lang_id == lang)
    return <div className={classes.wrapper} >
    <div className={classes.header} >
        {BreakpointsFilter[0] && <Breakpoints items={BreakpointsFilter[0]} />}
    </div>
    <div className={classes.bl_title} >
        <h1 className={classes.title}>
            {data?.data.title}
        </h1>
    </div>
    <Title className={classes.section_title} title={lang === "RU" ? "СГБс" : "SGBs "} subtitle={lang === "RU" ? "Профиль" : "Profiles "} first />
    <div className={classes.items} >
        {projects?.data.data.project.map((item) => <Card project={item} />)}
    </div>
	<Pagination
		forcePage={pageIndex}
		handlePageClick={handlePageClick}
		pageCount={projects?.data.data.count || 1}
	/>
</div>
}