import { Link, useSearchParams } from 'react-router-dom'
import Button from '../../../ui/Button'
import { Header } from '../Header'
import { ListMobile } from '../ListMobile'
import classes from './section.module.scss'
import { useEffect, useState } from "react"
import { ICategory } from '../../model/News'

export const Section = () => {
	let [searchParams, setSearchParams] = useSearchParams();
	const [selectedCategory, setSelectedCategory] = useState<string | null>(null)

	const lang = localStorage.getItem("lang");

	useEffect(() => {
		searchParams.set('news', selectedCategory ? selectedCategory : '')
		setSearchParams(selectedCategory ? `news=${selectedCategory}` : '')
	}, [selectedCategory, searchParams.get('news')])
	
	return <div
			className={classes.section}
			id={'news'}
		>
		<div className={classes.header}>
			<Header
				selectedCategory={selectedCategory}
				setSelectedCategory={setSelectedCategory}
			/>
		</div>
		<div className={classes.content} >
			<ListMobile
				selectedCategory={selectedCategory}
			/>
		</div>
		{selectedCategory && <div className={classes.footer} >
			<Button middle >
				<Link to={`/news/?news=${searchParams.get('news')}`}>
					{lang === "RU" ? "Показать все" : "Show all "}
				</Link>
			</Button>
		</div>}
	</div>
}