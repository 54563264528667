import classes from './check-content.module.scss'

interface Props {
	img: string
	id: number
	link_video?: string | null
}
export const CheckContent = ({ img, link_video, id }: Props) => {

	const url = 'https://www.youtube.com/watch?v=Zb99WEFojpI'

	let filterUrl

	if (link_video && link_video.startsWith('https', 0)) {
		filterUrl = link_video.slice(link_video.indexOf('watch?v=') + 8)
	}

	if (link_video && link_video.startsWith('https', 0)) {
		return <iframe className={classes.iframe} width="560" height="315" src={`https://www.youtube.com/embed/${filterUrl}`} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
	} else {
		return <img className={classes.img} src={img} alt={String(id)} />
	}
}


{/* <iframe className={classes.iframe} width="420" height="315"
			src={link_video}>
		</iframe> */}