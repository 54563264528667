import { getBearerToken } from "../../lib/getBearerToken"
import { axiosInstance } from "../axiosInstance"


export async function getFetcher(url: string) {
  try {
    const response = await axiosInstance.get(url, {
      headers: {
				'Accept-Language': getBearerToken()
      },
    })
    return response
  } catch (e) {
    throw e
  }
}
