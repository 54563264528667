import { Link } from 'react-router-dom'
import Title from '../../../ui/Title'
import classes from './section.module.scss'
import { useQuery } from '@tanstack/react-query';
import { getFetcher } from '../../../../shared/api/fetcher/getFetcher'
import { IProjectData } from '../../model/project'
import { Card } from '../Card/Card';
import { Swiper, SwiperSlide } from 'swiper/react';
import { A11y, Navigation, Pagination } from 'swiper/modules';
import Button from '../../../ui/Button';
import SliderNav from '../../../../shared/ui/SliderNav/SliderNav';
import clsx from 'clsx';


export const Section = () => {
	const lang = localStorage.getItem("lang");

    const { data, isError, isLoading } = useQuery<IProjectData>({
		queryKey: ['projects', lang],
		queryFn: () => {
			return getFetcher(`project/get`)
		}
	})


    if(isError && isLoading) {
        return null
    }

    return <div
            className={classes.section}
            id={'projects'}
        >
        <div className={classes.left} >
            <div className={classes.info} >
                <Title 
                    title={lang === 'RU' ? "СГБ" : 'SGBs'}
                    subtitle={lang === 'RU' ? 'Профили' : 'Profiles' }
                    // description={lang === 'RU' ? 'Критерии рассмотрения вашей заявки' : 'Criteria for consideration of your application'}
                />
            </div>
            {data?.data.data.project[0] && <Link 
                to={`/projects`} 
                className={classes.button} 
                onClick={() => {
                    window.scrollTo({ top: 0, behavior: 'smooth' });
                }}
                >
                {lang === 'RU' ? 'Показать все' : 'Show more'}
            </Link>}
        </div>
        <div className={classes.right} >
            <Swiper
                className={classes.items}
                spaceBetween={20}
                slidesPerView={2}
                modules={[Navigation, Pagination, A11y]}
                navigation={{
                    nextEl: '#swiper-button-next',
                    prevEl: '#swiper-button-prev',
                }}
                pagination={{ 
                    enabled: true,
                    // el: '#bullet-main-banner',
                    // bulletClass: 'slider-custom-pagination-bullet',
                    // bulletActiveClass: 'slider-custom-pagination-bullet-active',
                    clickable: true 
                }}
                >
                {data?.data.data.project?.map((item) => 
                    <SwiperSlide key={item.id}>
                        <Card project={item} />
                    </SwiperSlide> )}
            </Swiper>
            <SliderNav 
                className={classes.nav}
                prevId="swiper-button-prev"
                nextId="swiper-button-next"
                theme='light'
                >
                {/* <div id={'bullet-main-banner'}  
                    className={clsx(
                    "slider-custom-pagination",
                    "slider-custom-pagination-light",
                    classes.bullets
                    )} >
                </div> */}
            </SliderNav>
        </div>
</div>
}