import React from "react";
import styles from "./Innovative.module.scss";
import firstImg from "../../assets/img/innovative/01.png";
import secondImg from "../../assets/img/innovative/02.png";
import threeImg from "../../assets/img/innovative/03.png";
import { AnimationOnScroll } from "react-animation-on-scroll";

export default function Innovative() {

  const lang = localStorage.getItem("lang");



  return (
    <div className={styles.root}>
      <div className={styles.container}>
        <div className={styles.wrap}>
          <div className={styles.title}>
           { lang === "RU" ? "Инновационные" : "Innovative"} <span className={styles.titleColor}>{lang === "RU" ? "": "Climate"} </span>
            <br />
            { lang === "RU" ? "климатически-оптимизированные решения" : "Smart Solutions" }
          </div>
          <div className={styles.desc}>
            { lang === "RU" ?  "Инновационные климатически оптимизированные решения должны быть максимально адаптированы к местной среде, инклюзивными и которые могут быть масштабированы в случае успешной реализации пилотной фазы. " : "Innovative climate smart solutions that are contextual, scalable, and inclusive will be prioritized."}
            <div />
          </div>
        </div>
        {/* <AnimationOnScroll animateIn="animate__fadeIn"> */}
          <div className={styles.text}>
            { lang === "RU"?  `Проект определяет инновацию (новшество) как разработку новых и/или совершенствование/приспосабливание существующих методов работы, товаров, услуг, и технологий, которые могут решать вопросы, связанные с воздействием изменения климата в целевых странах. Предпосылкой к тому, чтобы идея стала восприниматься как новшество, является признание и удовлетворение ею, по мере возможности, потребностей социально обособленных групп, например, женщин, детей, и людей преклонного возраста. Как таковая, идея, которая воздействует негативно на социально обособленные группы, не может быть классифицирована как новаторская на основе критерий проекта. 
Проект будет поддерживать исключительно инновационные климатически оптимизированные решения и воздерживается от финансирования существующих проверенных решений. Поэтому, из-за инновационных рисков предлагаемые решения не могут быть профинансированы за счёт кредитов/возвратного капитала, но потенциально могут быть коммерчески жизнеспособными, технически осуществимыми и масштабируемыми в случае успешной реализации пилотной фазы.
` :
            `The Facility defines innovation as developing new and/or
            refining/adapting existing practices, goods, services, and
            technologies that have potential to address issues related to the
            impacts of climate change in the targeted countries more effectively
            than existing approaches. A pre-requisite for an idea to be
            categorized as an innovation is that it recognizes and addresses,
            where possible, the sensitive needs of marginalized groups such as
            women, children, and senior citizens. As such, an idea that
            negatively impacts marginalized groups cannot be categorized as
            innovative based on CSBCF’s criterion.\n The Facility focuses on
            supporting highly innovative climate-smart solutions and refrains
            from funding the replication or scaling up of existing, proven
            solutions. Therefore, proposed innovations must not yet be
            considered commercially viable, thus not qualifying for loans or
            repayable capital due to innovation risks, justifying the use of
            grant subsidies. Despite their current status, solutions should have
            the potential to become viable and scalable after successful pilot ""
            testing as evidenced by documented technical and financial results. ` 
          }
          </div>
        {/* </AnimationOnScroll> */}
      </div>
      {/* <AnimationOnScroll animateIn="animate__fadeIn">
        <div className={styles.images}>
          <img className={styles.firstImg} src={firstImg} alt="" />
          <div className={styles.fourImage}>
            <img className={styles.secondImg} src={secondImg} alt="" />
            <img className={styles.threeImg} src={threeImg} alt="" />
            <img className={styles.fourImg} src= {fourImg} alt="" />
            <img className={styles.fiveImg} src= {fiveImg} alt="" />
          </div>
        </div>
      </AnimationOnScroll> */}
    </div>
  );
}
