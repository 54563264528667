import React from "react";
import styles from "./ModalDownload.module.scss";
import downloadIcon from "../../../assets/icon/download.svg";
import Button from "../Button/Button";
import Contacts from "../../Contacts/Contacts";
import CONTANS from "../../../constans";

interface ModalDownloadProps {
  visible: boolean;
  onClose: () => void;
}

export default function ModalDownload({
  visible = false,
  onClose,
}: ModalDownloadProps) {
  const onKeydown = ({ key }: KeyboardEvent) => {
    switch (key) {
      case "Escape":
        onClose();
        break;
    }
  };
  React.useEffect(() => {
    document.addEventListener("keydown", onKeydown);
    return () => document.removeEventListener("keydown", onKeydown);
  });
  if (!visible) return null;

  const lang = localStorage.getItem("lang");
  return (
    <div className={styles.modal} onClick={onClose}>
      <div className={styles.modalDialog} onClick={(e) => e.stopPropagation()}>
        <div className={styles.modalBody}>
          <div className={styles.modalContent}>
            <img src={downloadIcon} alt="" />
            <div className={styles.wrapText}>
              <h1 className={styles.title}>
                {lang === "RU" ? "Как подать завяку" : "How to Apply"}
              </h1>
              <p className={styles.text}>
                {" "}
                {lang === "RU"
                  ? "Нажмите, чтобы скачать файл"
                  : "Click to download file"}{" "}
              </p>
            </div>
            <a href="/files/Call_for_Proposal_Guideline_and_Application_and_Budget_Templates.zip">
              {" "}
              <Button small onClick={onClose}>
                {lang === "RU" ? CONTANS.DOWNLOAD_RU :  CONTANS.DOWNLOAD}
              </Button>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
