import { Link } from "react-router-dom";
import { IProject } from "../../model/project";
import classes from "./card.module.scss";
import { Sanitize } from "../../../../shared/ui/Sanitize";

interface Props {
	project: IProject
};

export const Card = ({ project }: Props) => {

	const lang = localStorage.getItem("lang");
	
	return (
		<div className={classes.item}>
			<span className={classes.title}>{project.title}</span>
			<div className={classes.subtitle}>
				{project.description.replace(/<[^>]*>/g, '').replace(/&[^;]+;/g,'')}
			</div>
			<Link 
				to={`/projects/${project.id}`} 
				className={classes.link}
				onClick={() => {
					window.scrollTo({ top: 0, behavior: 'smooth' });
				}}
				/>
		</div>
	)
}
