import { Link } from "react-router-dom"
import { IBreakpointItem, IBreakpoints } from "../../model/IBreakpoints"
import classes from './breakpoints.module.scss'

interface Props {
	items: IBreakpoints
}


const Breakpoints = ({ items }: Props) => {
	return (
		<div>
			<ul className={classes.items} >
				{items.items.map((item) =>
					<li key={item.name} className={classes.item}>
						<Link to={item.link}>
							{item.name}
						</Link>
					</li>
				)}
			</ul>
		</div>
	)
}

export { Breakpoints }
