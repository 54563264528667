import styles from "./Card.module.scss";
interface ICard {
	id: number;
	icon: string;
	title: string;
	description: string;
	about?: boolean;
};

function Card({ id, icon, title, description, about }: ICard) {

	const lang = localStorage.getItem("lang");
	return (
		<div className={about ? styles.rootAbout : lang === "RU" ? styles.rootRU : styles.root}>
			<img className={about ? styles.iconAbout : styles.icon} src={icon} alt="image" />
			<span className={about ? styles.titleAbout : styles.title}>{title}</span>
			<span className={lang === "RU" ? styles.subtitleRU : styles.subtitle}>{description}</span>
		</div>
	)
}

export default Card;

