import financingIcon from "./assets/icon/financing.svg";
import locale from "../assets/icon/locale.svg";
import staff from "../assets/icon/staff.svg";
import turnover from "../assets/icon/turnover.svg";



export  const faqRU = [
  {
    id: 1,
    question: "В разработке",
    answer: "В разработке",
  },
  {
    id: 2,
    question: "В разработке",
    answer: "В разработке",
  },
];