import classNames from 'classnames';
import styles from './Lang.module.scss';


import ReactFlagsSelect from 'react-flags-select';
import { useNavigate } from 'react-router-dom';
// import 'react-flags-select/css/react-flags-select.css';

interface Ilang {
	locale: string;
}

export default function Lang({
	locale,
}: Ilang) {
	const navigate = useNavigate();

	function handleSetItem(lang: string) {
		localStorage.setItem("lang", lang)
		navigate("/");
		window.location.reload()
	}


	return (
		// </button> */}
		<ReactFlagsSelect
			className={styles.flag}
			id={styles.lang}
			onSelect={(l) => handleSetItem(l)}
			selected={locale}
			countries={["RU", "GB"]}
			customLabels={{
				"RU": "РУ",
				"GB": "GB",
			}}

			selectButtonClassName={styles.menuFlagsButton}
			optionsSize={16}
		/>

	)
}
