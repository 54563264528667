import clsx from 'clsx'
import classes from './card.module.scss'
import { INews } from "../../model/News"
import { Link } from 'react-router-dom'
import { Sanitize } from '../../../../shared/ui/Sanitize'

interface Props {
	newsItem: INews
	hideImage?: boolean
}

export const Card = ({ newsItem, hideImage }: Props) => {
	const description = newsItem.content.slice(0, 150)

	return <div
		className={classes.item}
		key={newsItem.id}>
		<div className={clsx(classes.item_body, hideImage && classes.NoMinheight )} >
				{ !hideImage && (
					<div className={classes.img_body}>
						<img
							className={classes.img}
							src={newsItem.img}
							alt={newsItem.title} 
						/>
					</div>
				)}
			<h3 className={classes.title}>
				{newsItem.title}
			</h3>
			<p className={classes.text}>
				{description.replace(/<[^>]*>/g, '')}
			</p>
			<Link
				className={classes.link}
				to={`/news/${newsItem.id}`}></Link>
		</div>
	</div>
}