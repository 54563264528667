import { useLocation, useSearchParams } from 'react-router-dom';
import { Header } from '../Header'
import { List } from '../List'
import classes from './news.module.scss'
import { useEffect, useState } from 'react';

export const News = () => {
	let [searchParams, setSearchParams] = useSearchParams();
	const [selectedCategory, setSelectedCategory] = useState<string | null>(searchParams.get('news'))


	useEffect(() => {
		window.scrollTo(0, 0)
		searchParams.set('news', selectedCategory ? selectedCategory : '')
		setSearchParams(selectedCategory ? `news=${selectedCategory}` : '')
	}, [selectedCategory, searchParams.get('news')])

	return <div className={classes.wrapper}>
		<Header
			selectedCategory={selectedCategory}
			setSelectedCategory={setSelectedCategory}
		/>
		<List
			selectedCategory={selectedCategory}
		/>
	</div>
}

