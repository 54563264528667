import "./styles/App.scss";
import "animate.css/animate.min.css";
import { Route, Routes } from "react-router-dom";
import Layout from "./components/layout/Layout";
import MainPage from "./pages/main-page";
import NewsPage from "./pages/news";
import NewsSlugPage from "./pages/news-slug";
import GalleryPage from "./pages/gallery";
import ProjectsPage from "./pages/projects";
import ProjectsSlugPage from "./pages/projects-slug";

function App() {

	return (
		<Routes>
			<Route path='/' element={<Layout />} >
				<Route index element={<MainPage />} />
				<Route path='news' element={<NewsPage />} />
				<Route path='gallery' element={<GalleryPage />} />
				<Route path='news/:id' element={<NewsSlugPage />} />
				<Route path='projects' element={<ProjectsPage />} />
				<Route path='projects/:id' element={<ProjectsSlugPage />} />
			</Route>
		</Routes>
	);
}

export default App;
