import financingIcon from "./assets/icon/financing.svg";
import locale from "../assets/icon/locale.svg";
import staff from "../assets/icon/staff.svg";
import turnover from "../assets/icon/turnover.svg";

export  const cardCriteriaData = [

  
    {
      id: 1,
      title: "Locally Registered",
      icon: locale,
      description:
        "Locally registered in one of the 3 participating countries and meet due diligence requirements.",
    },
    {
      id: 2,
      title: "Staff",
      description: `Employ at least 5 people and 
    ensure appropriate safeguarding 
    mechanisms will be followed.`,
      icon: staff,
    },
    {
      id: 3,
      title: "Turnover",
      icon: turnover,
      description: `Turnover of at least EUR 35,000 
    per annum demonstrated in 
    externally audited accounts.`,
    },
    {
      id: 4,
      title: "Co-financing",
      description:
        "Contribute at least 40% in co-financing (cash and/or in kind).",
      icon: locale,
    },
    {
      id: 5,
      title: "Innovations",
      icon: locale,
      description:
        "Innovative solutions which contribute to climate resilience",
    },
  ];