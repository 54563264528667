import React from "react";
import styles from "./About.module.scss";
import img from "../../assets/img/02.png";
import Title from "../ui/Title/Title";
import Card from "../ui/Card/Card";
import { AnimationOnScroll } from 'react-animation-on-scroll';


interface ICard {
  id: number;
  icon: string;
  title: string;
  description: string;
}
interface IAbout {
  title: string;
  subtitle: string;
  description: string;
  desc: string;
  cards: ICard[];
  image: string;
}

function About({ title, subtitle, desc, description, cards, image }: IAbout) {

  const lang = localStorage.getItem("lang");
  return (
    <main id = 'about' className={styles.container}>
     <AnimationOnScroll animateIn="animate__pulse">
      <img className={styles.img} src={img} alt="Climate Smart" />
      </AnimationOnScroll>
      <div className={styles.rigth}>
      {/* <AnimationOnScroll animateIn="animate__pulse" > */}
      <div className={styles.title}>
        {title}
        <span className={styles.subtitle}>{subtitle}</span>
        </div>
        <div className={styles.description}>
          {description}
        </div>
        <span className={styles.subdesc}> {desc} </span>
        {/* </AnimationOnScroll> */}
        <AnimationOnScroll animateIn="animate__pulse" >
        <div className={styles.card}> 
          {cards.map((card) => (
            <Card key={card.id} about= {true} id={card.id} icon={card.icon} title={card.title} description={card.description} />
          ))}
        </div>
        </AnimationOnScroll>
      </div>
    </main>
  );
}

export default About;
