import React, { useEffect, useState } from "react";
import { Link, animateScroll as scroll } from "react-scroll";
import styles from "./footer.module.scss";
import boLogo from "../../../assets/BO.svg";
import CONTANS from "../../../constans";
const Footer = () => {
  const lang = localStorage.getItem("lang");
  return (
    <div className={styles.root}>
      <div className={styles.wrap}>
        <ul className={styles.horizontal}>
          <li>
            <Link
              className={styles.link}
              activeClass="active"
              to="about"
              spy={true}
              smooth={true}
              offset={-70}
              duration={600}
            >
              {lang === "RU" ? CONTANS.ABOUT_RU : CONTANS.ABOUT}
            </Link>
          </li>
          <li>
            <Link
              className={styles.link}
              activeClass="active"
              to="criteria"
              spy={true}
              smooth={true}
              offset={-70}
              duration={900}
            >
           {lang === "RU" ? CONTANS.CRITERIA_RU : CONTANS.CRITERIA}
            </Link>
          </li>
          <li>
            <Link
              className={styles.link}
              activeClass="active"
              to="apply"
              spy={true}
              smooth={true}
              offset={-70}
              duration={900}
            >
              {lang === "RU" ? CONTANS.HOW_TO_APPLY_RU : CONTANS.HOW_TO_APPLY}
            </Link>
          </li>
          <li>
            <Link
              className={styles.link}
              activeClass="active"
              to="partners"
              spy={true}
              smooth={true}
              offset={-70}
              duration={900}
            >
              {lang === "RU" ? CONTANS.PARTNERS_RU : CONTANS.PARTNERS}
            </Link>
          </li>
          <li>
            <Link
              className={styles.link}
              activeClass="active"
              to="faq"
              spy={true}
              isDynamic={true}
              smooth={true}
              offset={-70}
              duration={1200}
            >
            {lang === "RU" ? CONTANS.FAQ_RU : CONTANS.FAQ}
            </Link>
          </li>
        </ul>

        <hr className={styles.dashed}></hr>
        <img className={styles.logo} src={boLogo} alt="" />
        <hr/>
      </div>
    </div>
  );
};

export default Footer;
