import styles from "./Header.module.scss";
import classNames from "classnames";
import logo from "../../../assets/logo.svg";
import Button from "../../ui/Button/Button";
import { Link } from "react-scroll";
import {useState, useEffect} from "react";
import Modal from "../../ui/Modal/Modal";
import ModalContacts from "../../../modal/ModalContacts";

import ReactFlagsSelect from 'react-flags-select';
import Lang from "../../ui/Lang/Lang";
import CONTANS from "../../../constans";
import {NavLink} from "react-router-dom";

function Header() {

  const [isModal, setModal] = useState(false)
  const onClose = () => setModal(false)


  const lang = localStorage.getItem("lang") ;

  useEffect (() => {
       
  }, [lang])



  return (
    <div className={classNames(styles.root)}>
      <header className={styles.header}>
        <div className={styles.wrap}>
          <a href="/" className={styles.logo}>
            <img
            className={styles.widthLogo}
              src={logo}
              alt="CSBCF"
              width={134}
              height={44}
              // className={logoHide && classes.HideHide || ''}
            />
          </a>
          {/* <input className={styles.menuBtn} type="checkbox" id="menu-btn" />
          <label className={styles.menuIcon} htmlFor="menu-btn">
            <span className={styles.navicon}></span>
          </label> */}
          <div className={styles.menu}>
          <ul className={styles.None} >
            <li>
              <Link
                activeClass="active"
                to="about"
                spy={true}
                smooth={true}
                offset={-70}
                duration={600}
              >
                {lang === "RU" ? CONTANS.ABOUT_RU : CONTANS.ABOUT}
              </Link>
            </li>
            <li>
              <Link
                  activeClass="active"
                  to="news"
                  spy={true}
                  smooth={true}
                  offset={-70}
                  duration={600}
              >
                {lang === "RU" ? CONTANS.NEWS_RU : CONTANS.NEWS}
              </Link>
            </li>
            <li>
              <Link
                activeClass="active"
                to="criteria"
                spy={true}
                smooth={true}
                offset={-70}
                duration={900}
              >
                  {lang === "RU" ? CONTANS.CRITERIA_RU : CONTANS.CRITERIA}
              </Link>
            </li>
            <li>
            <Link
                activeClass="active"
                to="apply"
                spy={true}
                smooth={true}
                offset={-70}
                duration={900}
              >
                {lang === "RU" ? CONTANS.HOW_TO_APPLY_RU : CONTANS.HOW_TO_APPLY}
              </Link>
            </li>
            <li>
            <Link
                activeClass="active"
                to="partners"
                spy={true}
                smooth={true}
                offset={-70}
                duration={900}
              >
       {lang === "RU" ? CONTANS.PARTNERS_RU : CONTANS.PARTNERS}
              </Link>
            </li>
            <li>
            <Link
                activeClass="active"
                to="faq"
                spy={true}
                isDynamic = {true}
                smooth={true}
                offset={-70}
                duration={1200}
              >
                 {lang === "RU" ? CONTANS.FAQ_RU : CONTANS.FAQ}
              </Link>
            </li>
            
          </ul>
         
         
          <span className={styles.buttons}>
          <Link
                activeClass="active"
                to="apply"
                spy={true}
                isDynamic = {true}
                smooth={true}
                offset={-70}
                duration={1200}
              >
          <Button className={styles.button}>
          {lang === "RU" ? CONTANS.APPLICATION_RU : CONTANS.APPLICATION_EN}
          </Button>
          </Link>
          <Link
                activeClass="active"
                to="contacts"
                spy={true}
                smooth={true}
                offset={-70}
                duration={900}
              >
                <Button type="outLine" className={styles.button}>
                {lang === "RU" ? "контакты" : "contacts"}
            </Button>
            </Link>
            
            </span>

           
            </div>
            <Lang locale={ lang ? lang : 'GB' }/>
        </div>
      </header>
      <Modal
                visible={isModal}
                title="Application"
                content={<ModalContacts/>}
                footer={<button onClick={onClose}>Закрыть</button>}
                onClose={onClose}
            />
    </div>
  );
}

export default Header;
