// import "./styles/App.scss";
// import supportImage from "./assets/icon/support.svg";
// import financingIcon from "./assets/icon/financing.svg";
import "animate.css/animate.min.css";
import Smart from "../../components/Smart";
import About from "../../components/About";
import Criteria from "../../components/Criteria";
import Innovative from "../../components/Innovative";
import DATA from "../../data";
import Apply from "../../components/Apply";
import Partners from "../../components/Partners";
import Questions from "../../components/Questions";
// './../../assets/icon/support.svg'
import support from './../../assets/icon/support.svg'
import financing from './../../assets/icon/financing.svg'


import { NewsHeader, NewsListMobile, NewsSection } from "../../components/News";
import Contacts from "../../components/Contacts";
import Work from "../../components/Work";
import { GallerySection } from "../../components/gallery";
import { ProfilesSection } from "../../components/profiles";


function MainPage() {
	const card = [
		{
			id: 1,
			title: "Technical support",
			description:
				"Technical support to SGBs to execute innovative climate-smart solutions",
			icon: support,
		},
		{
			id: 2,
			title: "Grants",
			description:
				"Access to much-needed grants to leverage additional private sector capital.",
			icon: financing,
		},
	];

	const cardRU = [
		{
			id: 1,
			title: "Техническую помощь",
			description:
				"Техническая помощь малым и растущим предприятиям в реализации инновационных климатических решений ",
			icon: support,
		},
		{
			id: 2,
			title: "Грантовое финансирование",
			description:
				"Доступ к необходимому финансированию и привлечение дополнительного капитала от частного сектора ",
			icon: financing,
		},
	];
	const lang = localStorage.getItem("lang");

	return (
		<>
			<Smart
				title={
					lang === "RU"
						? "Региональная климатическая инициатива для частного сектора"
						: "The Climate Smart Business Challenge Facility "
				}
				subtitle={lang === "RU" ? "" : "(CSBCF)"}
				description={
					lang === "RU"
						? "Пилотный проект “Региональная климатическая инициатива для частного сектора” поддерживает малые и растущие предприятия (МРП), уделяя особое внимание низкоуглеродным и устойчивым к изменению климата решениям в области сельского хозяйства и водных ресурсов в Таджикистане, Пакистане и Индии.x"
						: "Also referred to as Facility – is a pilot project that aims to identify and support Small and Growing Businesses (SGBs) focusing on low-carbon and climate-resilient solutions in Tajikistan, Pakistan, and India."
				}
				img={""}
			/>
			<About
				title={lang === "RU" ? "О проекте " : "About "}
				subtitle={"CSBCF"}
				description={
					lang === "RU"
						? `Региональная климатическая инициатива для частного сектора — это пилотный проект, реализуемый Фондом Ага Хана в Таджикистане в партнерстве с Германским агентством международного сотрудничества (GIZ). Этот проект поддерживается Программой малых грантов, которая является частью Международной климатической инициативы (МКИ/IKI), и финансируется Федеральным министерством экономики и борьбы с изменением климата Германии в тесном сотрудничестве с Федеральное министерство окружающей среды, охраны природы, ядерной безопасности и защиты прав потребителей (BMUV).
							Акцентируя внимание на взаимозависимость сельского хозяйства, лесного хозяйства, и других видов землепользования (СХЛХДВЗ) и водных ресурсов (как с точки зрения адаптации, так и смягчения изменений климата), проект стимулирует малые и растущие предприятия перенять практику «зелёного» бизнеса и тем самым способствует к использованию потенциала частного сектора для содействия повышению устойчивости к изменению климата.   
							`
						: `The Facility (CSBCF) is a pilot project implemented by Aga Khan Foundation Tajikistan. It is supported by the IKI Small Grants program strengthening local solutions for effective climate and biodiversity action. IKI Small Grants is part of Germany’s International Climate Initiative (IKI). It is funded by the Federal Ministry of Economic Affairs and Climate Action (BMWK), the Federal Foreign Office (AA) and the Federal Ministry for the Environment, Nature Conservation, Nuclear Safety and Consumer Protection (BMUV) and carried out by Deutsche Gesellschaft für Internationale Zusammenarbeit (GIZ) GmbH. Focusing on the agriculture, forestry, and 
							other land use (AFOLU) and water nexus (both adaptation and 
							mitigation), the facility will create incentives for SGBs to become 
							champions of green practices and tap into the potential of the private 
							sector to contribute to climate resilience`
				}
				desc={
					lang === "RU"
						? "Проект предоставляет:"
						: "The facility will provide:"
				}
				cards={lang === "RU" ? cardRU : card}
				image={""}
			/>
			<Criteria
				title={lang === "RU" ? "Квалифика ционные " : "Selection "}
				subTitle={lang === "RU" ? "критерии" : "criteria"}
				description={
					lang === "RU"
						? "Кто может претендовать на получение гранта"
						: "Eligibility criteria for applicants"
				}
				cards={
					lang === "RU" ? DATA.cardCriteriaDataRU : DATA.cardCriteriaData
				}
			/>
			{/* <Work title={"How its work"} description={"Criteria for passing for consideration of your application"}/> */}
			<NewsSection />
			<Innovative />
			<GallerySection />
			<Apply />
			<Partners />
			<ProfilesSection />
			<Questions
				faq={lang === "RU" ? DATA.faqRU : DATA.faq}
				title={lang === "RU" ? "Часто задаваемые " : "Frequently asked"}
				subtitle={lang === "RU" ? "вопросы" : "questions"}
			/>

			<Contacts />
		</>
	);
}

export default MainPage;
