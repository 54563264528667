
import React, { ReactNode, ReactElement, StrictMode, useEffect, useState } from  'react'
import styles from './Modal.module.scss'

interface ModalProps {
    visible: boolean
    title: string
    content: ReactElement | string
    footer: ReactNode | string
    onClose: () => void
}

export default function Modal({ 
     visible = false,
    title = '',
    content = '',
    footer = '',
    onClose, }: ModalProps) {

        const onKeydown = ({key}: KeyboardEvent) => {
            switch (key) {
                case 'Escape':
                    onClose()
                    break
            }
        }
        React.useEffect(() => {
            document.addEventListener('keydown', onKeydown)
            return () => document.removeEventListener('keydown', onKeydown)
        })
        if (!visible) return null;
  return (
    <div className={styles.modal} onClick={onClose}>
    <div className={styles.modalDialog} onClick={e => e.stopPropagation()}>
        <div className={styles.modalHeader}>
            <h3 className={styles.modalTitle}>{title}</h3>
            <span className={styles.modalClose} onClick={onClose}>
        &times;
      </span>
        </div>
        <div className={styles.modalBody}>
            <div className={styles.modalContent}>{content}</div>
        </div>
        {/* {footer && <div className={styles.modalFooter}>{footer}</div>} */}
    </div>
</div>
  )
}