import React, { ReactNode } from 'react'
import classNames from 'classnames'
import styles from './Title.module.scss'
import { AnimationOnScroll } from 'react-animation-on-scroll'
interface Props {
	className?: string
	children?: string
	description?: string
	subdesc?: string
	title?: ReactNode | string
	subtitle?: string
	first?: boolean
}
const Title = ({
	className,
	children,
	description,
	subdesc,
	title,
	subtitle,
	first
}: Props) => {
	return (
		<div className={classNames(className, styles.root)}>
			{!first ? <div className={styles.title}>
				{title}
				{subtitle && <span className={styles.subtitle}>&nbsp; {subtitle}</span>}
			</div> : <div className={styles.title}>
				{subtitle && <span className={styles.subtitle}>{subtitle}&nbsp;</span>}
				{title}
			</div>}
			<p className={styles.desc}>
				{description}
			</p>
		</div>
	)
}

export default Title