import React from 'react'
import styles from './Criteria.module.scss'
import Title from '../ui/Title/Title'
import Card from '../ui/Card/Card'
import { ICard } from '../../interfaces/ICard'
import { AnimationOnScroll } from 'react-animation-on-scroll'

interface ICriteria {
    title: string
    subTitle: string
    description: string
    cards: ICard[],

}
export default function Criteria({title, subTitle,  description, cards}: ICriteria) {
  return (
    <AnimationOnScroll animateIn="animate__FadeIn" >
    <div id = 'criteria' className={styles.container}>
        <Title className={styles.text} title={title} subtitle={subTitle} description={description} />
         {cards.map(card => (
            <Card key={card.id} icon={card.icon} id={card.id} title={card.title} description={card.description}  />
         ))
         }
    </div>
    </AnimationOnScroll>
  )
}
